import OrderComposerController from "../../../../../../controllers/OrderComposerController"
import Navigation from "../../../../../../models/navigation"

export default async ({ controller, setIsLoading }) => {

  setIsLoading(true)

  controller = new OrderComposerController()
  await controller.initialize(setIsLoading)

  controller.tabName = "Document"

  Navigation.createTab({
    component: "OrderComposer",
    params: {
      attribute: null,
      controller: controller
    },
    tabName: "Document"
  })

  setIsLoading(false)

}
