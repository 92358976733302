import models from "../../../../models"
import Permissions from "../../../../models/permissions"
import dbAccess from "../../../../services/dbRequest/dbAccess"
import handleLoginError from "../../../../services/handleLoginError"
import setTranslations from "../../../../services/setTranslations"
import showAlert from "../../../../services/showAlert"
import storeEmail from "./storeEmail"

interface LoginProps {
	email: string
	password: string
	keepLogged: boolean
	navigate: (screenName: string) => void
}

export default async function handleLogin({ email, password, keepLogged, navigate }: LoginProps) {
	try {
		await dbAccess.logIn(email, password)
		await Permissions.initialize()
		const modelsPromise = models.prepareClone()
		const translationPromise = setTranslations()
		storeEmail(email, password, keepLogged)

		const promisesArray = [modelsPromise, translationPromise]

		await Promise.all(promisesArray)

		navigate("Root")
	} catch (e) {
		console.error(e)
		const errorMessage = handleLoginError(e.code)
		showAlert({
			text: errorMessage,
			titleType: "error"
		})
	}
}
