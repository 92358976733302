import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const UserArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 30px 8px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  cursor: pointer;
  border-style: none;
  opacity: 1;
  transition: opacity 0.16s ease-in-out;
  transition: opacity 0.5s ease-in-out;

  &:active {
    opacity: 0.3;
  }
`

export const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 200px;
  color: ${({ theme }) => theme["gray-700"]};
`

export const UserFullName = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: start;

  transition: opacity 0.3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => (menuIsExpanded ? 1 : 0)};

  margin-bottom: 3px;
`

export const UserEmail = styled.p`
  font-size: 12px;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => (menuIsExpanded ? 1 : 0)};
  white-space: nowrap; /* Impede que o texto quebre em várias linhas */
  overflow: hidden; /* Esconde o texto que ultrapassa o tamanho da caixa */
  text-overflow: ellipsis; /* Adiciona os três pontinhos no final do texto */
`

export const ImageContainer = styled.div`
  position: relative;
  width: 46px;
  height: 46px;
  background-color: ${({ isDragging }) =>
    isDragging ? defaultTheme["gray-200"] : "transparent"};
  border: ${({ isDragging }) =>
    isDragging ? "2px dashed " + defaultTheme["gray-300"] : "none"};

  &:hover .edit-icon {
    opacity: 1;
  }

  &:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: ${({ modalVisible = true }) => (modalVisible ? 0.9 : 0.3)};
    transition: opacity 0.5s ease;
  }
`

export const UserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid ${defaultTheme["cyan-300"]};
  transition: opacity 0.5s ease;
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0.8;
  width: 40%;
  padding: 14px 10px 14px;
  border-radius: 10px;
  background-color: ${defaultTheme["white"]};
`

export const ModalTitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: ${defaultTheme["gray-700"]};
`

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 80%;
  margin-top: 3%;
  gap: 15px;
  justify-content: space-between;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  flex: 0.5;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const LeftContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  width: 100%;
  height: 50%;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
  outline: 1px solid ${defaultTheme["gray-200"]};
  border-radius: 6px;
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.6;
  padding: 10px;
  width: 100%;
  height: 100%;
  outline: 1px solid ${defaultTheme["gray-200"]};
  border-radius: 6px;
`

export const UploadOptionsContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease;
`

export const UploadButton = styled.label`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  span {
    font-size: 10px;
    color: ${defaultTheme["gray-700"]};
    font-weight: 500;

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${defaultTheme["gray-700"]};
    }
  }
`

export const TitleContent = styled.h3`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: ${defaultTheme["gray-700"]};
`

export const OverviewBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1;

  transition: opacity 0.5s ease;
`

export const InformationContainer = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 5px;
  margin-top: 10px;
`

export const FieldContainer = styled.div`
  width: 100%;
  max-width: 250px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const FieldLabel = styled.label`
  color: ${defaultTheme["cyan-700"]};
  font-weight: 500;
  font-size: 14px;
`

export const FieldValue = styled.p`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-600"]};
  font-size: 14px;
  outline: none;
  padding-left: 3px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: auto;
  margin-top: 10px;
  gap: 40px;
`

export const SaveButton = styled.button`
  background-color: ${defaultTheme["cyan-300"]};
  color: ${defaultTheme["white"]};
  width: 110px;
  height: 35px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 2px 0 5px #00000029;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${defaultTheme["cyan-500"]};
    transition: background-color 0.5s ease;
  }
`

export const CancelButton = styled(SaveButton)`
  background-color: ${defaultTheme["gray-200"]};
  color: ${defaultTheme["gray-700"]};

  &:hover {
    background-color: ${defaultTheme["gray-500"]};
    color: ${defaultTheme["white"]};
    transition: background-color 0.5s ease, color 0.5s ease;
  }
`
