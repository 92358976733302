import LoginData from "../../../../models/loginData"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import fetchCalendarFilter from "./fetchCalendarFilter"

const fetchCalendarCollections = async (setCalendarCollections: React.Dispatch<React.SetStateAction<any[]>>) => {

  const defaultCalendars = [
    {
      CalendarText: "My Calendar",
      CalendarId: LoginData.getLoginData().ID,
      CalendarColor: "#c43081"
    },
    {
      CalendarText: "Company",
      CalendarId: RuntimeConfiguration.getCurrentBranchData().ID,
      CalendarColor: "#ff7f50"
    }
  ]

  const filters = await fetchCalendarFilter()

  const resourcesData = await dbRequest.loadRecords("T_SET_RLU").where("ACTIVE", "==", true)
    .execute((response: any[]) => {
      return filterRecords({
        filters,
        data: response.filter(item => item.ID !== LoginData.getLoginData().ID)
      })
    })

  const resourcesCollection = resourcesData.map(item => {
    return {
      CalendarText: item.DESCRIPTION,
      CalendarId: item.ID,
      CalendarColor: generateRandomHexColor()
    }
  })

  setCalendarCollections(
    [...defaultCalendars, ...resourcesCollection].sort((a, b) => {
      if (a.CalendarText === "My Calendar") return -1
      if (b.CalendarText === "My Calendar") return 1
      return a.CalendarText.localeCompare(b.CalendarText)
    })
  )
}

function generateRandomHexColor(): string {
  const letters = "0123456789ABCDEF"
  let color = "#"
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export default fetchCalendarCollections

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj)
}

const filterRecords = ({ filters, data }) => {
  const finalFilteredArray = data.filter((record) => {
    return filters.every(({ field, operator, value }) => {
      const fieldValue = getNestedValue(record, field)

      switch (operator) {
        case "==":
          return fieldValue == value
        case "!=":
          return fieldValue != value
        case ">":
          return fieldValue > value
        case ">=":
          return fieldValue >= value
        case "<":
          return fieldValue < value
        case "<=":
          return fieldValue <= value
        case "array-contains":
          return Array.isArray(value) && value.includes(fieldValue)
        default:
          return false
      }
    })
  })
  return finalFilteredArray
}
