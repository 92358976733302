import { Ionicons, Feather } from "@expo/vector-icons"
import React from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../styles/themes/default"
import * as S from "./styles"

export default function MenuButton({
  option,
  menuIsExpanded,
  handleMenuClick,
  isScreenFocused
}) {
  const { t } = useTranslation()

  return (
    <S.ButtonWrapper key={option.ID} onClick={() => handleMenuClick(option)}>
      {isScreenFocused({ option }) && <S.SelectedMenuHighlighter />}
      {option.ICON ? (
        <S.MenuButton title={t(option.DESCRIPTION)}>
          <div style={{ width: 50 }}>
            <Feather
              name={option.ICON}
              size={21}
              color={
                isScreenFocused({ option })
                  ? defaultTheme["cyan-300"]
                  : defaultTheme["gray-700"]
              }
            />
          </div>
        </S.MenuButton>
      ) : (
        <div
          style={{
            width: 10,
            height: 30
          }}
        />
      )}
      <S.MenuText
        isSelected={isScreenFocused({ option })}
        menuIsExpanded={menuIsExpanded}
      >
        {t(option.DESCRIPTION)}
      </S.MenuText>

      {menuIsExpanded && option?.SUB_MENUS?.length > 0 && (
        <Ionicons
          name="caret-forward-circle-outline"
          size={21}
          color={defaultTheme["gray-500"]}
          style={{
            position: "absolute",
            right: 15
          }}
        />
      )}
    </S.ButtonWrapper>
  )
}
