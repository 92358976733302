import AsyncStorage from "@react-native-async-storage/async-storage"
import { Platform } from "react-native"
import LoginData from "../../models/loginData"
import Navigation from "../../models/navigation"
import RuntimeConfiguration from "../../models/runtimeConfiguration"
import showAlert from "../showAlert"
import dbRequest from "./dbRequest"

const LOCAL_STORAGE_KEY = "sessionToken"
const ASYNC_STORAGE_KEY = "@sessionToken"

const isWeb = Platform.OS === "web"

const checkSession = async (isMddRequisition) => {
  const navigation = Navigation.getNavigation()

  if (isMddRequisition) return true

  let localSessionToken = null
  const storedSessionToken = await dbRequest
    .loadRecords("-USER_RELATIONSHIP")
    .admin()
    .where("ID", "==", RuntimeConfiguration.getUserMddData().ID)
    .execute((r) => r[0].SESSIONID)

  if (isWeb) localSessionToken = localStorage.getItem(LOCAL_STORAGE_KEY)
  else localSessionToken = await AsyncStorage.getItem(ASYNC_STORAGE_KEY)

  if (localSessionToken === storedSessionToken) return true
  else {
    if (!localSessionToken) return false

    if (isWeb) localStorage.removeItem(LOCAL_STORAGE_KEY)
    else await AsyncStorage.removeItem(ASYNC_STORAGE_KEY)

    setTimeout(async () => {
      LoginData.clear()
      if (Platform.OS === "web") {
        window.location.reload()
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: "Login" }]
        })
      }
    }, 5000)

    await showAlert({
      text: "You were disconnected because new access was detected on another device.",
      title: "Session expired",
      titleType: "error",
      handleConfirm: async () => {
        if (Platform.OS === "web") {
          window.location.reload()
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: "Login" }]
          })
        }
      }
    })
  }
}

export default checkSession
