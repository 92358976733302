import styled from "styled-components"

export const Container = styled.button`
  align-items: flex-end;
  background-color: ${({ isTabSelected, theme }) =>
    theme[isTabSelected ? "cyan-300" : "gray-200"]};
  border-top-left-radius: 3px;
  border-top-right-radius: 18px;
  box-shadow: 3px 0px 5px #00000029;
  display: flex;
  flex-direction: row;
  height: 50%;
  margin-left: ${({ index }) => (index === 0 ? "0" : "-16")}px;
  position: relative;
  padding-bottom: 6px;
  width: ${({ tabWidth }) => tabWidth};
  z-index: ${({ index }) => 100 - index};
  transition: width 0.3s ease-in-out;

  &:hover {
    width: ${({ fullTextWidth }) => fullTextWidth}px;
    & > div:first-child {
      white-space: nowrap;
      width: 85%;
      text-overflow: unset;
    }
  }
`

export const TextContainer = styled.div`
  color: ${({ isTabSelected, theme }) =>
    isTabSelected ? theme["white"] : theme["gray-500"]};
  font-size: 14px;
  font-weight: bold;
  left: 22px;
  text-align: start;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 65%;
  transition: width 0.4s ease-in-out;
`

export const CloseButton = styled.div`
  background-color: ${({ theme }) => theme["white"]};
  border-radius: 50%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 15px;
`
