import React, { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import OrderPanelController from "../../../controllers/PanelController"
import { defaultTheme } from "../../../styles/themes/default"
import Grid from "../../components/Grid"
import LoadingAnimation from "../../components/LoadingAnimation/index.web"
import ModalWrapper from "../../components/Modal"
import * as S from "./styles.web"
import copyRecord from "./utils/orderFunctions/web/copyRecord"
import deleteRecord from "./utils/orderFunctions/web/deleteRecord"
import insertRecord from "./utils/orderFunctions/web/insertRecord"
import switchMode from "./utils/orderFunctions/web/switchMode"

export default function OrderPanelScreen() {
  const dispatch = useDispatch()

  const gridRef = useRef(null)

  const [controller] = useState(new OrderPanelController())

  const [isLoading, setIsLoading] = useState(false)

  const props = {
    controller,
    entity: "T_MOV_HDR",
    dispatch,
    isLoading,
    setIsLoading,
    gridRef
  }

  const functions = {
    handleInsert: () => insertRecord(props),
    handleDelete: () => deleteRecord(props),
    handleCopy: () => copyRecord(props)
  }

  return (
    <S.Container>
      <Grid
        entity="T_MOV_HDR"
        functions={functions}
        gridRef={gridRef}
        models={controller.models()}
        screenType="orderPanel"
        rowDoubleClickCallback={() => switchMode(props)}
      />

      {isLoading && (
        <ModalWrapper isVisible={isLoading}>
          <S.LoadingContainer>
            <LoadingAnimation size="large" color={defaultTheme["cyan-700"]} />
          </S.LoadingContainer>
        </ModalWrapper>
      )}

    </S.Container>
  )
}
