import dbRequest from "../../../../services/dbRequest/dbRequest"
import evaluateFunction from "../../../../services/evaluateFunction"

const fetchCalendarFilter = async () => {
  try {
    const calendarFilter = await dbRequest
      .loadRecords("T_DTD_TAB")
      .where("ID", "==", "T_REC_CAL")
      .execute() as any

    if (calendarFilter && calendarFilter.length > 0) {
      if (calendarFilter[0]?.FILTER) return evaluateFunction(calendarFilter[0].FILTER)
    }
    return []
  } catch (error) {
    console.error("Erro ao carregar coleções:", error)
  }
}

export default fetchCalendarFilter
