import { Platform } from "react-native"
import storageRequest from "../../../../storageRequest"

const saveFilesToStorage = async ({ record }) => {

  const { ID, IMG } = record

  const hasAttachment = Object.keys(record).some(key => {
    if (Array.isArray(record[key])) {
      return Platform.OS === "web" ?
        record[key].some(item => item instanceof File)
        : record[key].some(item => item.devicePath)
    }
  })

  if (record.IMG?.file || record.IMG?.devicePath) {
    const { file } = IMG

    const result = await saveFile({
      path: `${ID}/IMG/${file.name || file.NAME}`,
      file
    })

    record.IMG = [result]
  } else if (IMG?.file === null) record.IMG = []

  if (hasAttachment) {
    const attachmentFields = Object.keys(record).filter(key => {
      if (Array.isArray(record[key])) {
        return Platform.OS === "web" ?
          record[key].some(item => item instanceof File)
          : record[key].some(item => item.devicePath)

      }
      return false
    })

    for (const field of attachmentFields) {
      const fieldFiles = record[field]

      const updatedFiles = []

      for (const file of fieldFiles) {
        if (file instanceof File || file.devicePath) {
          const result = await saveFile({
            path: `${ID}/${field}/${file.name || file.NAME}`,
            file
          })

          updatedFiles.push(result)
        } else updatedFiles.push(file)
      }
      record[field] = updatedFiles
    }
  }

}

export default saveFilesToStorage

const saveFile = async ({ path, file }) => {
  return await storageRequest
    .saveFiles(path)
    .file(file)
    .execute((res) => {
      const result = {
        NAME: file.name || file.NAME,
        SIZE: file.size || file.SIZE,
        TYPE: file.type || file.TYPE,
        URL: res.url
      }
      return result
    })

}
