import { deleteDoc, doc } from "firebase/firestore/lite"
import checkSession from "../../checkSession"
import { dbConnection } from "../../dbConnection"

export default async function deleteRecords(admin, collection, record) {
  if (!(await checkSession(admin))) new Error("Session expired")
  return await deleteDoc(
    doc(dbConnection(admin), collection, String(record.ID))
  )
}
