import { useEffect, useState } from "react"
import getTableData from "../../../../services/registration/getTableData"

export default function useTable(props) {
  const { updateTable } = props

  const [tableData, setTableData] = useState({})
  const [isFetchingTableData, setIsFetchingTableData] = useState(false)

  useEffect(() => {
    setIsFetchingTableData(true)
      ; (async () => {
        await getTableData(props).then((response) => {
          setTableData(response)
          setIsFetchingTableData(false)
        })
      })()
  }, [updateTable])

  return {
    isFetchingTableData,
    tableData,
    setTableData
  }
}
