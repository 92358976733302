import { Internationalization } from "@syncfusion/ej2-base"
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars"
import { CheckBoxSelection, DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns"
import { Inject } from "@syncfusion/ej2-react-schedule"
import React, { useRef } from "react"
import "../../styles.css"

export default function Sidebar({ resourceObj, scheduleObj, workWeekObj, calendarCollections, setScheduleSettings, scheduleSettings }) {

  const panelRef = useRef(null)
  const intl = new Internationalization()

  const minorSlotData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const weekDays = [
    {
      text: "Sunday",
      value: 0
    },
    {
      text: "Monday",
      value: 1
    },
    {
      text: "Tuesday",
      value: 2
    },
    {
      text: "Wednesday",
      value: 3
    },
    {
      text: "Thursday",
      value: 4
    },
    {
      text: "Friday",
      value: 5
    },
    {
      text: "Saturday",
      value: 6
    }
  ]
  const majorSlotData = [
    {
      Name: "30 minutes",
      Value: 30
    },
    {
      Name: "1 hour",
      Value: 60
    },
    {
      Name: "1.5 hours",
      Value: 90
    },
    {
      Name: "2 hours",
      Value: 120
    },
    {
      Name: "2.5 hours",
      Value: 150
    },
    {
      Name: "3 hours",
      Value: 180
    },
    {
      Name: "3.5 hours",
      Value: 210
    },
    {
      Name: "4 hours",
      Value: 240
    },
    {
      Name: "4.5 hours",
      Value: 270
    },
    {
      Name: "5 hours",
      Value: 300
    },
    {
      Name: "5.5 hours",
      Value: 330
    },
    {
      Name: "6 hours",
      Value: 360
    },
    {
      Name: "6.5 hours",
      Value: 390
    },
    {
      Name: "7 hours",
      Value: 420
    },
    {
      Name: "7.5 hours",
      Value: 450
    },
    {
      Name: "8 hours",
      Value: 480
    },
    {
      Name: "8.5 hours",
      Value: 510
    },
    {
      Name: "9 hours",
      Value: 540
    },
    {
      Name: "9.5 hours",
      Value: 570
    },
    {
      Name: "10 hours",
      Value: 600
    },
    {
      Name: "10.5 hours",
      Value: 630
    },
    {
      Name: "11 hours",
      Value: 660
    },
    {
      Name: "11.5 hours",
      Value: 690
    },
    {
      Name: "12 hours",
      Value: 720
    }
  ]
  const timeFormatData = [
    {
      Name: "12 hours",
      Value: "hh:mm a"
    },
    {
      Name: "24 hours",
      Value: "HH:mm"
    }
  ]
  const weekNumberData = [
    {
      Name: "Off",
      Value: "Off"
    },
    {
      Name: "First Day of Year",
      Value: "FirstDay"
    },
    {
      Name: "First Full Week",
      Value: "FirstFullWeek"
    },
    {
      Name: "First Four-Day Week",
      Value: "FirstFourDayWeek"
    }
  ]
  const tooltipData = [
    {
      Name: "Off",
      Value: "Off"
    },
    {
      Name: "On",
      Value: "On"
    }
  ]

  const onResourceChange = (args) => {
    setScheduleSettings((prevState) => ({
      ...prevState,
      selectedCalendars: args.value
    }))
  }

  const weekNumberChange = (args) => {
    if (args.value === "Off") {
      scheduleObj.current.showWeekNumber = false
      setScheduleSettings((prevState) => ({
        ...prevState,
        showWeekNumber: false
      }))
    }
    else {
      scheduleObj.current.showWeekNumber = true
      scheduleObj.current.weekRule = args.value
      setScheduleSettings((prevState) => ({
        ...prevState,
        showWeekNumber: true,
        weekRule: args.value
      }))
    }
  }

  const tooltipChange = (args) => {
    if (args.value === "Off") {
      scheduleObj.current.eventSettings.enableTooltip = false
      setScheduleSettings((prevState) => ({
        ...prevState,
        enableTooltip: false
      }))
    }
    else {
      scheduleObj.current.eventSettings.enableTooltip = true
      setScheduleSettings((prevState) => ({
        ...prevState,
        enableTooltip: true
      }))
    }
  }

  return (
    <div className='right-panel hide' ref={panelRef}>
      <div className='control-panel e-css' style={{
        height: "70%",
        boxShadow: "2px 2px 12px 2px rgba(0, 0, 0, 0.3)",
        borderRadius: 8
      }}
      >
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Calendar</label>
          </div>
          <div className='col-right'>
            <div id="multigroup" className="control-styles">
              <MultiSelectComponent
                id="resources"
                dataSource={calendarCollections}
                fields={{
                  text: "CalendarText",
                  value: "CalendarId"
                }}
                placeholder="Select calendars"
                value={scheduleSettings.selectedCalendars}
                mode="CheckBox"
                cssClass='schedule-resource'
                ref={resourceObj}
                showSelectAll={true}
                showDropDownIcon={true}
                enableSelectionOrder={true}
                allowFiltering={true}
                showClearButton={true}
                filterBarPlaceholder="Search calendars"
                popupHeight="350px"
                change={onResourceChange}
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </div>
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>First Day of Week</label>
          </div>
          <div className='col-right'>
            <DropDownListComponent id="weekFirstDay"
              dataSource={weekDays}
              fields={{
                text: "text",
                value: "value"
              }}
              value={scheduleSettings.firstDayOfWeek}
              popupHeight={400}
              change={(args) => {
                scheduleObj.current.firstDayOfWeek = args.value
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  firstDayOfWeek: args.value
                }))
              }}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Work week</label>
          </div>
          <div className='col-right'>
            <MultiSelectComponent
              id="workWeekDays"
              cssClass='schedule-workweek'
              ref={workWeekObj}
              dataSource={weekDays}
              mode='CheckBox'
              fields={{
                text: "text",
                value: "value"
              }}
              enableSelectionOrder={false}
              showClearButton={false}
              showDropDownIcon={true}
              value={scheduleSettings.workDays}
              change={(args) => {
                scheduleObj.current.workDays = args.value
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  workDays: args.value
                }))
              }}
            >
              <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Day Start Hour</label>
          </div>
          <div className='col-right'>
            <TimePickerComponent
              id='dayStartHour'
              showClearButton={false}
              //value={new Date(new Date().setHours(0, 0, 0))}
              change={(args) => {
                const startHour = intl.formatDate(args.value, { skeleton: "Hm" })
                scheduleObj.current.startHour = startHour
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  startHour
                }))
              }}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Day End Hour</label>
          </div>
          <div className='col-right'>
            <TimePickerComponent
              id='dayEndHour'
              showClearButton={false}
              value={new Date(new Date().setHours(23, 59, 59))}
              change={(args) => {
                const endHour = intl.formatDate(args.value, { skeleton: "Hm" })
                scheduleObj.current.endHour = endHour
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  endHour
                }))
              }}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Work Start Hour</label>
          </div>
          <div className='col-right'>
            <TimePickerComponent
              id='workHourStart'
              showClearButton={false}
              value={new Date(new Date().setHours(9, 0, 0))}
              change={(args) => {
                const startHour = intl.formatDate(args.value, { skeleton: "Hm" })
                scheduleObj.current.workHours.start = startHour
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  workStartHour: startHour
                }))
              }}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Work End Hour</label>
          </div>
          <div className='col-right'>
            <TimePickerComponent
              id='workHourEnd'
              showClearButton={false}
              value={new Date(new Date().setHours(18, 0, 0))}
              change={(args) => {
                const endHour = intl.formatDate(args.value, { skeleton: "Hm" })
                scheduleObj.current.workHours.end = endHour
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  workEndHour: endHour
                }))
              }}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Slot Duration</label>
          </div>
          <div className='col-right'>
            <DropDownListComponent
              id="slotDuration"
              dataSource={majorSlotData}
              fields={{
                text: "Name",
                value: "Value"
              }}
              value={60}
              popupHeight={150}
              change={(args) => {
                scheduleObj.current.timeScale.interval = args.value
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  slotDuration: args.value
                }))
              }}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Slot Interval</label>
          </div>
          <div className='col-right'>
            <DropDownListComponent
              id="slotInterval"
              dataSource={minorSlotData}
              value={2}
              popupHeight={150}
              change={(args) => {
                scheduleObj.current.timeScale.slotCount = args.value
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  slotInterval: args.value
                }))
              }}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Time Format</label>
          </div>
          <div className='col-right'>
            <DropDownListComponent
              id="timeFormat"
              dataSource={timeFormatData}
              fields={{
                text: "Name",
                value: "Value"
              }}
              value={"hh:mm a"}
              popupHeight={150}
              change={(args) => {
                scheduleObj.current.timeFormat = args.value
                setScheduleSettings((prevState) => ({
                  ...prevState,
                  timeFormat: args.value
                }))
              }}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Tooltip</label>
          </div>
          <div className='col-right'>
            <DropDownListComponent
              id="tooltip"
              dataSource={tooltipData}
              fields={{
                text: "Name",
                value: "Value"
              }}
              value={"Off"}
              popupHeight={150}
              change={tooltipChange}
            />
          </div>
        </div>
        <div className='col-row'>
          <div className='col-left'>
            <label style={{
              lineHeight: "34px",
              margin: "0"
            }}>Week Numbers</label>
          </div>
          <div className='col-right'>
            <DropDownListComponent
              id="weekNumber"
              dataSource={weekNumberData}
              fields={{
                text: "Name",
                value: "Value"
              }}
              value={"Off"}
              popupHeight={150}
              change={weekNumberChange}
            />
          </div>

        </div>
      </div>
    </div>
  )
}
