import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import UploadModal from "./Modal/"
import * as S from "./styles"

export default function Attachment({ models, field }) {
  const { t } = useTranslation()

  const { ID, TABLENAME, T_DTD_ATR } = field
  const { EDITABLE, FIELDDESCRIPTION } = T_DTD_ATR

  const [modalVisible, setModalVisible] = useState(false)

  const openModal = () => {
    setModalVisible(true)
  }

  const hasFiles = () => Array.isArray(models[TABLENAME]?.[ID]) ? models[TABLENAME]?.[ID]?.length > 0 : false

  return (
    <S.Container
      isEditable={EDITABLE}
      disabled={!EDITABLE}
      onClick={openModal}
      hasValue={hasFiles()}
    >
      <S.Icon />

      <span>{t(FIELDDESCRIPTION)}</span>

      {modalVisible &&
        <UploadModal
          field={field}
          models={models}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      }
    </S.Container>
  )
}
