import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"
import * as S from "./styles"

export default function SearchInput({ searchText, setSearchText, viewType }) {
  const { t } = useTranslation()

  const inputRef = useRef(null)

  const clearInput = () => { setSearchText("") }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!["registration", "orderPanel"].includes(viewType)) return

      const isCtrlPressed = event.ctrlKey
      const isKeyQ = event.key === "q" || event.key === "Q"

      if (isCtrlPressed && isKeyQ) {
        event.preventDefault()
        inputRef.current.focus()
      }
    }

    if (Platform.OS === "web") window.addEventListener("keydown", handleKeyDown)

    return () => {
      if (Platform.OS === "web") window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <S.Container>
      <S.Input
        ref={inputRef}
        value={searchText}
        onChangeText={setSearchText}
        selectTextOnFocus
        placeholder={`${t("Search")}`}
        autoFocus
      />

      <S.Button onPress={clearInput}>
        <S.Icon name={searchText === "" ? "search" : "times"} />
      </S.Button>
    </S.Container>
  )
}
