import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import LoginData from "../../../../../models/loginData"
import Observable from "../../../../../models/Observable"
import BuildForm from "../BuildForm"
import * as S from "./styles.web"

interface TabProps {
  tabName: string;
  isDetail: boolean;
  id: string;
}

export const formObserver = new Observable()

export default function RenderTabs(props) {
  const { t } = useTranslation()
  const { tabs, models, hideTabs } = props
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  formObserver.unsubscribeAll()
  const updateBackground = () => setSelectedTab(tabs[0])
  formObserver.subscribe(updateBackground)

  const [fullTextWidth, setFullTextWidth] = useState(100)
  const textRef = useRef(null)

  useEffect(() => {
    if (textRef.current) {
      const fullWidth = textRef.current.scrollWidth
      setFullTextWidth(fullWidth + 40)
    }
  }, [selectedTab.tabName])

  return (
    <S.Container>
      {!hideTabs && (
        <S.TabsContainer>
          {tabs.map((tab: TabProps, index: number) => {
            const { tabName } = tab
            const isTabVisible = shouldRenderTab(tab, models)
            const isTabSelected = selectedTab.tabName === tabName

            return (
              isTabVisible && (
                <S.Tab
                  key={tabName}
                  index={index}
                  title={t(tabName)}
                  isSelected={isTabSelected}
                  fullTextWidth={fullTextWidth}
                  onClick={() => setSelectedTab(tab)}
                >
                  <S.TitleContainer ref={isTabSelected ? textRef : null}>
                    {t(tabName)}
                  </S.TitleContainer>
                </S.Tab>
              )
            )
          })}
        </S.TabsContainer>
      )}

      <S.TabContent>
        <BuildForm {...props} selectedTab={selectedTab} />
      </S.TabContent>
    </S.Container>
  )
}

function shouldRenderTab(tab: TabProps, models: any) {
  const { ADMIN } = LoginData.getLoginData()
  const { isDetail, id } = tab

  if (!ADMIN && isDetail) {
    const { permissions } = models._REGISTRATION_DATA[id]
    return Boolean(permissions.READ)
  }

  return true
}
