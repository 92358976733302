import React from "react"
import { Platform } from "react-native"
import DynamicField from "../DynamicField"
import Container from "./styles"

export default function RenderField({ field, props }) {
  const { FIELDTYPE } = field

  const isDetailScreen = ["E", "R"].includes(FIELDTYPE.VALUE)
  const shouldAlignInEnd = ["A", "B"].includes(FIELDTYPE.VALUE)

  return (
    <Container
      isDetailScreen={isDetailScreen}
      style={
        shouldAlignInEnd && {
          alignSelf: "flex-end",
          width: Platform.OS === "web" ? "" : "42%"
        }
      }
    >
      <DynamicField {...props} field={field} />
    </Container>
  )
}
