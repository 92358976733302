export default class TransactionsData {
  private static transactions: object[]
  private static transactionsTemplate: object = {}

  //GET
  static getTransactions(): object[] {
    return TransactionsData.transactions
  }

  //SET
  static setTransactions(param: object[]): void {
    TransactionsData.transactions = param
  }

}
