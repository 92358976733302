import dbRequest from "../../../../../services/dbRequest/dbRequest"
import allRequiredFieldsFilled from "../../../../../services/registration/allRequiredFieldsFilled"
import runTriggers from "../../../../../services/registration/runTriggers"
import showAlert from "../../../../../services/showAlert"
import existingIDValidation from "./utils/save/existingIDValidation"
import manualIdValidation from "./utils/save/manualIdValidation"
import populateDetailTabsPath from "./utils/save/populateDetailTabsPath"
import populateModels from "./utils/save/populateModels"
import userScreenValidation from "./utils/save/userScreenValidation"

export default async function saveRecord(props) {
  const isIdValid = manualIdValidation(props)
  if (!isIdValid) return

  showAlert({
    type: "confirm",
    text: "Do you want to save the record?",
    title: "Record saving",
    handleConfirm: () => handleSave(props)
  })
}

export async function handleSave(props) {
  const { models, entityName, setUpdateForm, callback = () => null } = props

  callback()

  //Verifica se todos os campos obrigatórios foram preenchidos
  const areAllRequiredFieldsFilled = await allRequiredFieldsFilled({
    entity: entityName,
    models
  })
  if (!areAllRequiredFieldsFilled) return callback()

  //Verifica se o ID já existe
  const doesIdExists = await existingIDValidation(props)
  if (doesIdExists) return callback()

  //Verifica se o usuário é válido
  const isUserValid = await userScreenValidation(props) //Migrate to user screen
  if (!isUserValid) return callback()

  const tableData = models._REGISTRATION_DATA[entityName]

  tableData.state = "BEFORE"

  //Verifica se todos os triggers são válidos
  const areAllTriggersValid = await runTriggers(models, entityName)
  if (!areAllTriggersValid) return callback()

  const response = await dbRequest
    .saveRecords(entityName)
    .record(models[entityName])
    .execute()
  if (response.error) {
    callback()
    return showAlert({
      text: response.error,
      title: "Error",
      titleType: "error"
    })
  } else {
    models[entityName].populate(response)
  }

  await populateDetailTabsPath(props)

  populateModels(props)

  await runTriggers(models, entityName)

  callback()
  showAlert({
    text: "Record saved successfully",
    title: "Record saving",
    titleType: "success"
  })

  setUpdateForm(s => !s)
}

