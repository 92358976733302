import React from "react"
import { Platform } from "react-native"
import normalize from "../../../../../../../styles/normalizeSize"
import getFieldToRender from "./utils/getFieldToRender"
import screenBuilderValidation from "./utils/screenBuilderValidation"

export default function DynamicField({
  field,
  models,
  updateForm,
  setUpdateForm
}) {
  const { T_DTD_ATR, FIELDTYPE } = field

  const isFieldVisible = T_DTD_ATR.VISIBLE
  if (!isFieldVisible) return

  const shouldRenderField = screenBuilderValidation(models, field)
  if (!shouldRenderField) return

  const fieldProps = {
    field,
    models,
    setUpdateForm,
    updateForm,
    width: sizeConvert(field)
  }

  const FieldComponent = getFieldToRender(field)

  if (FieldComponent) return <FieldComponent {...fieldProps} />

  console.log(
    `Invalid FIELDTYPE for ${T_DTD_ATR.FIELDDESCRIPTION}:`,
    FIELDTYPE.VALUE
  )
  return <></>
}

/* const { width } = Dimensions.get("window")

const isTablet = width >= 600 && width <= 900 */

function sizeConvert({ T_DTD_ATR, FIELDTYPE }) {
  if (!["S", "M"].includes(FIELDTYPE.VALUE) || !T_DTD_ATR.SIZE)
    return Platform.OS === "web" ? 160 : normalize(180)

  switch (T_DTD_ATR.SIZE.VALUE) {
    case "M":
      return Platform.OS === "web" ? 300 : normalize(200)
    case "L":
      return Platform.OS === "web" ? 800 : normalize(300)
    default:
      return Platform.OS === "web" ? 160 : normalize(160)
  }
}
