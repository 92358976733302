/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-prototype-builtins */
import { collection, doc, setDoc } from "firebase/firestore/lite"
import globalDate from "../../../../global/globalDate"
import LoginData from "../../../../models/loginData"
import checkSession from "../../checkSession"
import { dbConnection } from "../../dbConnection"
import saveFilesToStorage from "./utils/saveFilesToStorage"

export default async function saveRecords(admin, _collection, record) {

  try {
    if (!await checkSession(admin)) new Error("Session expired")

    const creationDate = new globalDate().toISO()

    const { ID, DESCRIPTION, IMG = [] } = LoginData.getLoginData()

    const userObj = {
      ID,
      DESCRIPTION,
      IMG
    }

    const userTimestamps = {
      UPDATED_BY: userObj,
      UPDATED_AT: creationDate
    }

    if (!record.CREATED_AT) {
      // @ts-ignore
      userTimestamps.CREATED_AT = creationDate
      // @ts-ignore
      userTimestamps.CREATED_BY = userObj
    }

    if (record.ID) {
      const docRef = doc(dbConnection(admin), _collection, `${record.ID}`)

      await saveFilesToStorage({ record })

      delete record.ID

      await setDoc(docRef, {
        ...record,
        ...userTimestamps
      })

      record.ID = docRef.id

      return {
        ...record,
        ...userTimestamps
      }
    }

    delete record?.ID

    const docRef = doc(collection(dbConnection(admin), _collection))
    record.ID = docRef.id

    await saveFilesToStorage({ record })

    await setDoc(docRef, {
      ...record,
      ...userTimestamps
    })

    return {
      ...record,
      ...userTimestamps
    }
  } catch (error) {
    console.error(error)
    throw new Error(error.message)
  }

}

