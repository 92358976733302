import dbRequest from "../../../../../services/dbRequest/dbRequest"
import User from "../../../../../services/dbRequest/user"
import showAlert from "../../../../../services/showAlert"

export default async ({ models, entity }) => {
  if (entity !== "T_SET_RLU") return true

  const { ACTIVE, ADMIN, ID, DESCRIPTION, EMAIL } = models[entity]

  const hasAdminUsers = await hasRemainingAdminUsers(models, entity)

  if ((!ACTIVE || !ADMIN) && !hasAdminUsers)
    return showAlert({
      text: "Minimum number of active administrators reached",
      title: "Failed to save",
      titleType: "error"
    })

  if (!isValidEmail(EMAIL))
    return showAlert({
      text: "Invalid email",
      title: "Failed to save",
      titleType: "error"
    })

  let isValid = true

  if (models._REGISTRATION_DATA[entity].operation === "INS") {
    const uid = await User.create({
      name: DESCRIPTION,
      email: EMAIL,
      isActive: ACTIVE
    })
    uid ? models[entity].ID = uid : isValid = false
  }

  else if (ACTIVE !== models._REGISTRATION_DATA[entity].originalRecord.ACTIVE)
    ACTIVE ? isValid = await User.enableUser(ID) : await User.disableUser(ID)

  return isValid || showAlert({
    text: "Max users reached. Verify your contract info",
    title: "Failed to save",
    titleType: "error"
  })
}

function isValidEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailPattern.test(email)
}

async function hasRemainingAdminUsers(models, entity) {
  const users = await dbRequest
    .loadRecords(entity)
    .where("ACTIVE", "==", true)
    .where("ADMIN", "==", true)
    .execute() as any

  if (users.length === 1 && users[0].ID === models[entity].ID) return false

  return users.length > 0
}
