/**
 * Column class
 * @private
 */
/* eslint-disable */
var Column = /** @class */ (function () {
    function Column() {
    }
    return Column;
}());
export { Column };
