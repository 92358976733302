import { Dimensions, PixelRatio } from "react-native"

const normalize = (size: number) => {
  const { width: screenWidth, height: screenHeight } = Dimensions.get("window")

  const scale = Math.min(screenWidth / 415)

  const isTablet = screenWidth >= 800 && screenHeight > 1000

  const tabletFactor = isTablet ? 0.95 : 1.0

  return Math.round(PixelRatio.roundToNearestPixel(size * scale * tabletFactor))
}

export default normalize
