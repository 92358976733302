import styled from "rn-css"
import globalStyles from "../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.tertiary};
  height: 25px;
  margin: 5px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`

export const AmountText = styled.Text`
  font-size: 16px;
  color: ${globalStyles.colors.primary};
`
