import { Feather } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["white"]};
	display: flex;
  justify-content: space-between;
  height: 60px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
  height: 100%;
  padding: 0 15px;
`

interface InputProps {
  isInputFocused: boolean
}

export const SearchContainer = styled.div<InputProps>`
  align-items: center;
  border-color: ${({ isInputFocused }) => defaultTheme[isInputFocused ? "cyan-300" : "gray-500"]};
  border-radius: 10px;
  border-style: solid;
  border-width: ${({ isInputFocused }) => isInputFocused ? 1.5 : 0.9}px;
  cursor: text;
  display: flex;
  justify-content: center;
  position: relative;
  transition: border-color 0.4s ease-in-out, border-width 0.4s ease-in-out; 
  z-index: 10;
  padding: 6px;
`

export const Input = styled.input<InputProps>`
  border: none;
  color: ${defaultTheme["gray-700"]};
  font-size: 16px;
  outline: none;
  padding: 0 ${({ isInputFocused }) => isInputFocused ? 8 : 0}px; 
  transition: width 0.4s ease-in-out; 
  width: ${({ isInputFocused }) => isInputFocused ? 250 : 0}px;
  z-index: 9;
`

export const BranchLogo = styled.img`
  width: 38px;
  height: 38px;
  object-fit: contain;
  border-radius: 10px;
`

export const Icon = styled(Feather)
  .attrs({
    color: defaultTheme["gray-500"],
    name: "search",
    size: 22
  })``
