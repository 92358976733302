import TransactionsData from "../models/transactionsData"
import dbRequest from "./dbRequest/dbRequest"
import getTableFields from "./registration/getTableFields"

const getTransctionsData = async () => {

  const transactionsRecords = await dbRequest.loadRecords("T_SET_TNS").execute(async (response) => {
    const mappedTransactions = response.map(async (transaction) => {
      const { ID } = transaction

      const navigationFields = await getTableFields({
        collectionPath: `T_MOV_HDR/T_SET_HDR/${ID}`,
        entityName: "T_MOV_HDR"
      }).then((fields) => {
        return fields.filter((field) => field.T_DTD_ATR.VISIBLE)
      })

      return {
        ...transaction,
        navigationFields
      }
    })

    return Promise.all(mappedTransactions)
  })

  TransactionsData.setTransactions(transactionsRecords)
  return transactionsRecords
}

export default getTransctionsData
