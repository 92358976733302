import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ModalWrapper from "../../../../../Modal"
import FileCard from "./components/FileCard/index.web"
import FileSelection from "./components/FileSelection"
import * as S from "./styles.web"
import handleDelete from "./utils/handleDelete"

export default function UploadModal({ modalVisible, setModalVisible, field, models }) {
  const { t } = useTranslation()

  const { ID, TABLENAME, T_DTD_ATR } = field

  const [filesPreview, setFilesPreview] = useState(models[TABLENAME][ID] ?? [])

  const closeModal = () => {
    setModalVisible(false)
    models[TABLENAME][ID] = [...filesPreview]
  }

  const allowMultipleFiles = T_DTD_ATR.MULTIPLEFILES
  const isFileListEmpty = filesPreview.length === 0

  const shouldSelectFile = allowMultipleFiles || isFileListEmpty

  const renderCard = (file, index) => (
    <FileCard
      key={index}
      index={index}
      file={file}
      handleDeleteFile={() => handleDelete({
        index,
        callback: setFilesPreview
      })}
    />
  )

  return (
    <ModalWrapper isVisible={modalVisible}>
      <S.Container>
        <S.Title>{t("Change")} {t(T_DTD_ATR.FIELDDESCRIPTION)}</S.Title>

        <S.CloseButton onClick={closeModal}>
          <S.CloseIcon />
        </S.CloseButton>

        {shouldSelectFile && <FileSelection setFilesPreview={setFilesPreview} />}

        <S.SeparatorView>
          <S.SeparatorLine />
          <S.SeparatorText>{t("Files")}</S.SeparatorText>
          <S.SeparatorLine />
        </S.SeparatorView>

        <div style={{ overflowY: "scroll" }}>
          <S.FilesListContainer>
            {isFileListEmpty
              ? <S.PlaceholderList>{t("No files selected")}</S.PlaceholderList>
              : filesPreview.map(renderCard)
            }
          </S.FilesListContainer>
        </div>
      </S.Container>
    </ModalWrapper>
  )
}
