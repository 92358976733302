import { L10n, registerLicense, loadCldr, setCulture } from "@syncfusion/ej2-base"
import ptGregorian from "@syncfusion/ej2-cldr-data/main/en-GB/ca-gregorian.json"
import ptNumberData from "@syncfusion/ej2-cldr-data/main/en-GB/numbers.json"
import pttimeZoneData from "@syncfusion/ej2-cldr-data/main/en-GB/timeZoneNames.json"
import ptNumberingSystem from "@syncfusion/ej2-cldr-data/supplemental/numberingSystems.json"
import { Grid } from "@syncfusion/ej2-react-grids"
import React from "react"
import { MenuProvider } from "react-native-popup-menu"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"
import "./src/utils/loadSyncfusionStyles"
import globalDate from "./src/global/globalDate"
import store from "./src/redux/store"
import Routes from "./src/routes"
import { GlobalStyle } from "./src/styles/global"
import { gridStyles } from "./src/styles/grid"
import { defaultTheme } from "./src/styles/themes/default"
import syncfusionTranslations from "./src/utils/syncfusionTranslations.json"

registerLicense("Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlcdnVQQmZYU0F/W0Q=")
loadCldr(ptNumberData, pttimeZoneData, ptGregorian, ptNumberingSystem)
L10n.load({
  "pt": {
    ...syncfusionTranslations["pt-BR"]
  }
})
setCulture("pt")

import "./src/utils/i18n/i18n"

export default function App() {
  global.globalDate = globalDate

  new Grid()
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <style>{gridStyles}</style>
      <Provider store={store}>
        <MenuProvider>
          <div id="web-modal"></div>
          <Routes />
        </MenuProvider>
      </Provider>
    </ThemeProvider>
  )
}
