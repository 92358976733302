/* eslint-disable import/prefer-default-export */
import styled from "styled-components"

export const Container = styled.button`
  width: 38px;
  height: 38px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme["cyan-800"]};

  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme["cyan-300"]};
    box-shadow: 3px 0px 5px #00000029;
  }
`
