import globalDate from "../../../../global/globalDate"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

const handleSave = async ({ eventData }) => {

  try {
    const { Id, CalendarId, StartTime, EndTime, IsAllDay, IsPlanned, Location, Subject, ProjectId, PartnerId, RecurrenceRule, RecurrenceException } = eventData

    const record = {
      ID: Id,
      CALENDAR_ID: CalendarId,
      PROJECT_ID: ProjectId ?? "",
      PARTNER_ID: PartnerId ?? "",
      END_TIME: new globalDate(EndTime).toISO(),
      IS_ALL_DAY: IsAllDay ?? false,
      IS_PLANNED: IsPlanned ?? false,
      LOCATION: Location ?? "",
      RECURRENCE_RULE: RecurrenceRule ?? false,
      START_TIME: new globalDate(StartTime).toISO(),
      SUBJECT: Subject,
      RECURRENCE_EXCEPTION: RecurrenceException ?? ""
    }

    await dbRequest
      .saveRecords("T_REC_CAL")
      .record(record)
      .execute()

    showAlert({
      text: "Event saved successfully",
      titleType: "success"
    })
  } catch (error) {
    showAlert({
      text: "Error when trying to save event",
      titleType: "error"
    })
  }

}

export default handleSave
