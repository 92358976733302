import dbRequest from "../../services/dbRequest/dbRequest"
import evaluateFunction from "../../services/evaluateFunction"
import removeFieldsFromObject from "../../services/removeFieldsFromObject"
import showAlert from "../../services/showAlert"

export default class DefaultController {

  constructor(params) {

    // this.modelsTemplate = params.models()
    this.models = () => params.orderComposerController.models()
    this.OrderComposerController = params.orderComposerController
    this.detailTabPath = params.detailTabPath
    this.masterEntity = this.OrderComposerController?.masterEntity ?? "T_MOV_HDR"
    this.saved = params.saved
    this.isInitialScreen = params.initialScreen

    this.filters = params.filters
    this.where = params.where
    this.screenName = params.name
    this.collectionName = params.collectionName
    this.listEntityName = () => params.collectionName
    this.entityFields = () => params.entityFields
    this.getHDRfield = () => params.HDRfield

    this.saveValues = async item => {
      this.models()[this.masterEntity][params.HDRfield.ID] = {
        ID: item.ID,
        DESCRIPTION: item.DESCRIPTION,
        IMG: item.IMG?.[0]?.URL ?? ""
      }
      Object.keys(item).forEach(key => this.models()[this.listEntityName()][key] = item[key])

      if (this.models()[this.masterEntity].ID) {
        await dbRequest
          .saveRecords(this.masterEntity)
          .record(removeFieldsFromObject({ ...this.models()[this.masterEntity] }))
          .execute(() => {
            showAlert({
              titleType: "success",
              title: " ",
              text: "Alteração salva com sucesso",
              type: "ok"
            })
          })
      }
    }
  }

  async getEntityFilters() {
    const filtersArray = await evaluateFunction(this.filters ?? "return []", this.models())
    if (this.listEntityName() === "T_SET_TNS") {
      filtersArray.push({
        field: "TYPE.VALUE",
        operator: "==",
        value: this.masterEntity
      })
    }
    return filtersArray
  }

  async getRecords(setIsLoading) {
    const filtersArray = await evaluateFunction(this.filters ?? "return []", this.models())

    if (this.listEntityName() === "T_SET_TNS") {
      filtersArray.push({
        field: "TYPE.VALUE",
        operator: "==",
        value: this.masterEntity
      })
    }

    let request = dbRequest.loadRecords(this.listEntityName())

    if (this.where?.length) {
      for (const codition of this.where) {
        if (codition.VALUE.includes("return")) {
          codition.VALUE = await evaluateFunction(codition.VALUE, this.models())
        }
        if (codition.VALUE) request = request.where(codition.FIELD, codition.OPERATOR.VALUE, codition.VALUE)
      }
    }

    return await request.execute((responseData) => this.applyDefaultFilters(responseData, filtersArray, setIsLoading))
  }

  getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj)
  }

  applyDefaultFilters = async (data, filters, setIsLoading) => {

    const finalFilteredArray = data.filter(record => {
      return filters.every(({ field, operator, value }) => {
        const fieldValue = this.getNestedValue(record, field)
        switch (operator) {
          case "==":
            return fieldValue == value
          case "!=":
            return fieldValue != value
          case ">":
            return fieldValue > value
          case ">=":
            return fieldValue >= value
          case "<":
            return fieldValue < value
          case "<=":
            return fieldValue <= value
          case "array-contains":
            return Array.isArray(value) && value.includes(fieldValue)
          default:
            return false
        }
      })
    })

    if (finalFilteredArray.length === 1 && this.listEntityName() === "T_SET_TNS") {
      setIsLoading(true)
      await this.OrderComposerController.changeScreen(finalFilteredArray[0], this, setIsLoading)
      return []
    } else return finalFilteredArray

  }

  startChangeScreen = (i, setIsLoading) => {
    this.OrderComposerController.changeScreen(i, this, setIsLoading)
  }

  formatCpfCnpj = (value) => {
    return value?.length === 11
      ? value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      : value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
  }
}
