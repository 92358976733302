import RuntimeConfiguration from "../../models/runtimeConfiguration"
import dbRequest from "../dbRequest/dbRequest"
import evaluateFunction from "../evaluateFunction"
import getTableFields from "./getTableFields"

export default async ({ entityName, staticData, screenBuilder, models, staticRecords }) => {

  let tableData = {}

  if (staticData) tableData = staticData[0]
  else {

    const isAdminRequest = RuntimeConfiguration.getIsDeveloperConfigurationMode()

    let request = dbRequest.loadRecords("T_DTD_TAB").where("ID", "==", entityName)

    if (isAdminRequest && screenBuilder) request = request.admin()

    tableData = await request.execute(([r]) => r)

    if (staticRecords) tableData.records = await staticRecords
    else {
      let requestRecords = await dbRequest.loadRecords(entityName)
      if (isAdminRequest && screenBuilder) requestRecords = requestRecords.admin()
      tableData.records = await requestRecords.execute()
    }
  }

  const tableFields = models._REGISTRATION_DATA[entityName].fields ?? await getTableFields({
    entityName,
    isScreenBuilderRequest: true
  })

  models._REGISTRATION_DATA[entityName].fields = tableFields

  const visibleFields = tableFields.filter(({ FIELDTYPE, T_DTD_ATR }) => T_DTD_ATR.GRIDVISIBLE && FIELDTYPE.VALUE !== "E")

  const filters = await evaluateFunction(tableData.FILTER ?? "return []")

  return {
    fields: visibleFields.sort((a, b) => a.T_DTD_ATR.GRIDORDERBY - b.T_DTD_ATR.GRIDORDERBY),
    detailTabs: tableFields.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE === "E"),
    records: tableData.records,
    filters,
    isHierarchic: tableData.TYPE.VALUE === "Hierarchic",
    masterTable: tableData.MASTERTAB?.ID ?? tableData.MASTERTAB
  }
}
