import React, { useEffect } from "react"
import { FlatList } from "react-native"
import LoadingAnimation from "../LoadingAnimation"
import Footer from "./components/Footer"
import HierarchicalTree from "./components/HierarchicalTree"
import TableHeader from "./components/TableHeader"
import TableRow from "./components/TableRow"
import useTable from "./hooks/useTable"
import * as S from "./styles.web"
import getFilteredRecords from "./utils/getFilteredRecords"

export default function Table(props) {

  let { models, searchText, setRecordsAmount = () => null, viewType, entityName, updateTable, controller } = props

  if (!models) models = controller.models()

  const { tableData, isFetchingTableData, setTableData } = useTable({
    ...props,
    models
  })

  const { fields = [], isHierarchic = false, detailTabs = [], masterTable = null } = tableData

  let { filteredRecords } = getFilteredRecords({
    tableData,
    models,
    viewType,
    searchText,
    entityName
  })

  const renderItem = ({ item, index }) => (
    <TableRow
      {...props}
      rowData={item}
      rowIndex={index}
      fields={fields}
      detailTabs={detailTabs}
      masterTable={masterTable}
    />
  )

  useEffect(() => {
    setRecordsAmount(filteredRecords.length)
  }, [searchText, updateTable, filteredRecords])

  return (
    <S.Container isHorizontal={!isHierarchic} contentContainerStyle={{ flex: 1 }}>
      {isFetchingTableData ? (
        <S.LoadingDiv>
          <LoadingAnimation size='large' />
        </S.LoadingDiv>
      ) : isHierarchic ? (
        <HierarchicalTree {...props} records={filteredRecords} />
      ) : (
        <S.ListContainer>
          <TableHeader
            entityName={entityName}
            models={models}
            fields={fields}
            filteredRecords={filteredRecords}
            setTableData={setTableData}
          />

          <FlatList
            data={filteredRecords}
            renderItem={renderItem}
            initialNumToRender={30}
            keyExtractor={({ ID }, index) => ID ?? index}
          />

          <Footer fields={fields} tableRecords={filteredRecords} />
        </S.ListContainer>
      )}
    </S.Container>
  )
}
