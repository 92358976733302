import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import idValidation from "../../utils/idValidation"
import AccessibilityBox from "../AccessibilityBox"
import useInputManager from "../hooks/useInputManager"
import maskedInputStyle from "./maskedInputStyle"
import * as S from "./styles.web"

export default function Integer({ models, field, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  const { TABLENAME, ID } = field
  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT, SIZE, ONFOCUS } = field.T_DTD_ATR
  const { state, updateValues, onBlur } = useInputManager({
    models,
    field,
    updateForm,
    setUpdateForm
  })

  const width = sizeConvert(SIZE?.VALUE)

  const commonProps = {
    value: state,
    isEditable: TABLENAME === "T_SET_RLU" && ID === "EMAIL" && models._REGISTRATION_DATA[TABLENAME].operation === "UPD" && models[TABLENAME][ID] ? false : EDITABLE,
    disabled: TABLENAME === "T_SET_RLU" && ID === "EMAIL" && models._REGISTRATION_DATA[TABLENAME].operation === "UPD" && models[TABLENAME][ID] ? true : !EDITABLE,
    onBlur,
    width
  }

  const [currentMask, setCurrentMask] = useState(getMaskType(field.T_DTD_ATR.MASK, models[TABLENAME][ID]))

  function getMaskType(mask, value) {
    if (mask === "cnpj-cpf") {
      if (!value) return "999.999.999-99"
      if (value.length <= 11) {
        return "999.999.999-99"
      } else {
        return "99.999.999/9999-99"
      }
    } else {
      return mask
    }
  }

  const handleChange = (e) => {
    updateValues(e.target.value)
  }

  const handleKeyDown = (e) => {
    const stateAsString = String(state)
    if (field.T_DTD_ATR.MASK !== "cnpj-cpf") return
    if (stateAsString.length === 11 && e.key.match(/\d/)) {
      setCurrentMask("99.999.999/9999-99")
    } else setCurrentMask(getMaskType(field.T_DTD_ATR.MASK, stateAsString))
  }

  return (
    <S.Container>
      <style>{maskedInputStyle}</style>

      <S.Row>
        <S.Title isRequired={REQUIRED}>{t(FIELDDESCRIPTION)}</S.Title>
        <AccessibilityBox text={HELPTEXT} />
      </S.Row>

      {field.T_DTD_ATR.MASK ? (
        <S.MaskedInput
          {...commonProps}
          mask={currentMask}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoFocus={ONFOCUS}
        />
      ) : (
        <S.Input
          {...commonProps}
          placeholder={t(FIELDDESCRIPTION)}
          onChange={e => updateValues(e.target.value)}
          autoFocus={ONFOCUS}
        />
      )}
    </S.Container>
  )
}

function sizeConvert(size: string) {
  switch (size) {
    case "M": return 300
    case "L": return 800
    default: return 160
  }
}
