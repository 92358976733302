import getTableFields from "../../../../../../../../../../services/registration/getTableFields"

export default async function getFields({ configurationPath, t }) {

  console.log("configurationPath", configurationPath)

  const tableFields = await getTableFields({
    entityName: "T_SET_TNS",
    fetchOptions: false,
    collectionPath: configurationPath
  })

  const formattedFields = tableFields
    .map(({ ID, T_DTD_ATR, FIELDTYPE }) => ({
      id: ID,
      description: T_DTD_ATR.FIELDDESCRIPTION ?? "",
      editable: T_DTD_ATR.EDITABLE ?? false,
      required: T_DTD_ATR.REQUIRED ?? false,
      visible: T_DTD_ATR.VISIBLE ?? false,
      order: T_DTD_ATR.ORDERBY ?? 9999,
      type: FIELDTYPE.VALUE,
      expression: T_DTD_ATR.EXPRESSION
    }))

  const visible = formattedFields
    .filter(({ visible }) => visible)
    .sort((a, b) => a.order - b.order)

  const nonVisible = formattedFields
    .filter(({ visible }) => !visible)
    .sort((a, b) => t(a.description).localeCompare(t(b.description)))

  return {
    visible,
    nonVisible
  }
}
