/**
 * AutoFilters class
 * @private
 */
/* eslint-disable */
var AutoFilters = /** @class */ (function () {
    function AutoFilters() {
    }
    return AutoFilters;
}());
export { AutoFilters };
