/* eslint-disable no-case-declarations */
import {
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  RowDataBoundEventArgs
} from "@syncfusion/ej2-react-grids"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator } from "react-native"
import { Field } from "../../../services/registration/getTableFields"
import { defaultTheme } from "../../../styles/themes/default"
import applySettings from "../Toolbars/components/CustomSettings/utils/applySettings"
import GridToolbar from "../Toolbars/Grid"
import RenderColumn from "./components/Column"
import useGrid from "./hooks/useGrid"
import * as S from "./styles"
import getAggregates from "./utils/getAggregates"
import getFormattedRecords from "./utils/getFormattedRecords"
import handleRowPress from "./utils/handleRowPress"
import services from "./utils/services"

export interface GridProps {
  entity: string;
  gridRef?: React.RefObject<GridComponent> | null;
  functions?: {
    handleInsert?: () => void;
    handleCopy?: () => void;
    handleDelete?: ({ changeLoadingModalVisibility }: { changeLoadingModalVisibility: () => void }) => void;
    handleRefresh?: () => void;
  };
  models?: any;
  rowDoubleClickCallback?: (record?: object) => void;
  detailTabPath?: string;
  screenType?: "registrationMaster" | "registrationDetail" | "orderPanel" | "searchModal";
  updateGrid?: boolean;
  staticFilters?: string
}

export default function Grid({
  entity,
  gridRef,
  functions,
  models,
  rowDoubleClickCallback,
  detailTabPath = null,
  screenType,
  updateGrid,
  staticFilters
}: GridProps) {
  const { t } = useTranslation()

  const {
    fieldsData,
    records,
    updateGridRecords,
    updateGridFields,
    loading
  } = useGrid({
    entity,
    models,
    detailTabPath,
    updateGrid,
    gridRef,
    screenType,
    staticFilters
  })

  const { fields, detailTabs } = fieldsData
  const aggregates = getAggregates(fields)

  const editOptions = {
    allowAdding: true,
    allowDeleting: true
  }

  const selectionSettings = {
    type: "Single",
    checkboxMode: "ResetOnRowClick"
  }

  const renderAggregate = useCallback(
    ({ operation, field }) => {
      const isCurrencyField = field.FIELDTYPE.VALUE === "C"
      const isIntegerField = field.FIELDTYPE.VALUE === "I"

      const valuePrefix = isCurrencyField ? "R$ " : ""
      const defaultDecimalValue = isIntegerField ? 0 : 2

      const fixedValue = props => {
        const value = typeof props[operation] === "number" ? props[operation] : 0
        return (value).toFixed(field.T_DTD_ATR.DECIMAL ?? defaultDecimalValue)
      }

      const aggregateTemplate = props => (
        <S.FooterItem>
          <S.BoldText>{t(operation)}:</S.BoldText>
          <span>{valuePrefix}{fixedValue(props)}</span>
        </S.FooterItem>
      )

      return (
        <AggregateColumnDirective
          key={field.ID}
          field={field.ID}
          type={operation}
          footerTemplate={aggregateTemplate}
          groupFooterTemplate={aggregateTemplate}
        />
      )
    },
    [t]
  )

  const toolbarTemplate = () => (
    <GridToolbar
      gridRef={gridRef}
      entity={entity}
      models={models}
      screenType={screenType}
      functions={{
        ...functions,
        handleRefresh: async () => {
          gridRef.current.showSpinner()
          await updateGridRecords()
          gridRef.current.hideSpinner()
        }
      }}
    />
  )

  const formattedRecords = getFormattedRecords({
    records,
    models,
    entity
  })

  /* if (gridRef.current !== null && models._REGISTRATION_DATA[entity].savedProperties) applySettings({
    settings: models._REGISTRATION_DATA[entity].savedProperties,
    fields,
    gridRef
  }) */

  const onRowPress = (args: RowDataBoundEventArgs) => {
    args.row.style.cursor = screenType === "searchModal" ? "pointer" : "default"

    handleRowPress({
      args,
      grid: gridRef.current,
      entity,
      models,
      detailTabs,
      records,
      rowDoubleClickCallback,
      screenType
    })
  }

  const pageSettings = {
    pageSize: screenType === "searchModal" ? 100 : 50,
    pageCount: 4,
    pageSizes: [30, 50, 100, 200, "All"]
  }

  return (
    <S.Container>
      <style>
        {`
          div.e-gridcontent {
            height: calc(100% - ${screenType === "searchModal" ? 65 + aggregates.length * 27 : 164 + aggregates.length * 27}px) !important;
          }
        `}
      </style>

      <GridComponent
        id={"Grid" + entity}
        key={"Grid" + entity}
        allowExcelExport={true}
        allowFiltering={true}
        allowGrouping={screenType !== "searchModal" ?? false}
        allowMultiSorting={true}
        allowPaging={true}
        allowReordering={true}
        allowResizing={true}
        allowSelection={true}
        allowSorting={true}
        dataSource={loading ? [] : formattedRecords}
        editSettings={editOptions}
        enableHover={true}
        locale="pt"
        pageSettings={pageSettings}
        filterSettings={{
          type: "Excel",
          showFilterBarStatus: true
        }}
        emptyRecordTemplate={() => {
          if (!loading && !formattedRecords.length) return (
            <div style={{
              position: "absolute",
              top: 20,
              left: "50%",
              width: "100%",
              height: "100%"
            }}>
              <span style={{ color: defaultTheme["gray-500"] }}>{t("No records to display")}</span>
            </div>
          )
          if (loading) return (
            <div style={{
              position: "absolute",
              top: 20,
              left: 0,
              width: "100%",
              height: "100%"
            }}>
              <ActivityIndicator size={"small"} color={defaultTheme["cyan-800"]} />
            </div>
          )
        }
        }
        gridLines="Both"
        height="100%"
        ref={gridRef}
        rowDataBound={onRowPress}
        selectionSettings={selectionSettings}
        showColumnChooser={true}
        toolbar={[""]}
        toolbarTemplate={toolbarTemplate}
        width={"100%"}
      >
        <ColumnsDirective>
          <ColumnDirective
            type="checkbox"
            width={80}
            textAlign="Center"
            headerTextAlign="Center"
            visible={screenType !== "searchModal" ?? true}
          />
          {fields
            .filter(({ ID, FIELDTYPE }) => FIELDTYPE.VALUE === "A" ? ID === "IMG" : true)
            .map((field) => RenderColumn({
              fieldData: field,
              t
            })
            )}
        </ColumnsDirective>

        <AggregatesDirective>
          {aggregates.map(({ operation, fields }) => (
            <AggregateDirective key={operation}>
              <AggregateColumnsDirective>
                {fields.map((field: Field) => renderAggregate({
                  operation,
                  field
                }))}
              </AggregateColumnsDirective>
            </AggregateDirective>
          ))}
        </AggregatesDirective>

        <Inject services={services} />
      </GridComponent>

    </S.Container >
  )
}
