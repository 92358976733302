import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Navigation from "../../../models/navigation"
import Observable from "../../../models/Observable"
import MenuDrawer from "../MenuDrawer/index.web"
import TranslationModal from "../TranslationModal"
import SuggestionsList from "./components/SuggestionsList"
import TabsContainer from "./components/TabsContainer"
import * as S from "./styles.web"
import getRoutines from "./utils/getRoutines"
import handleNavigation from "./utils/handleNavigation"

export const headerObserver = new Observable()

export default function Header(props) {
  const { t } = useTranslation()

  Navigation.setNavigation(props.navigation)

  const inputRef = useRef(null)

  const [isInputFocused, setIsInputFocused] = useState(false)
  const [textInput, setTextInput] = useState("")
  const [selectedTabName, setSelectedTabName] = useState("Overview")
  const [routines, setRoutines] = useState([])
  const [update, setUpdate] = useState({})

  headerObserver.unsubscribeAll()
  const updateRoutines = () => {

    setUpdate(s => !s)
  }
  headerObserver.subscribe(updateRoutines)

  useEffect(() => {
    getRoutines().then(setRoutines)

    const handleKeyDown = (event) => {
      if (event.key === "Home") {
        event.preventDefault()
        setSelectedTabName("Overview")
        props.navigation.navigate("Overview")
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [update])

  const handleInputClick = () => {
    setIsInputFocused(true)
    inputRef.current.focus()
  }

  const handleRoutineClick = (routine) => {
    handleNavigation(routine)
    setTextInput("")
    setIsInputFocused(s => !s)
  }

  const lowercasedInput = textInput.toLowerCase()
  const validateValue = (value: string | undefined) => value && t(value).toLowerCase().includes(lowercasedInput)

  const filteredRoutines = textInput === ""
    ? []
    : routines.filter(({ ID, NAVID, TYPE, DESCRIPTION }) => (
      validateValue(ID) || validateValue(NAVID) || validateValue(TYPE?.VALUE) || validateValue(DESCRIPTION)
    ))
  return (
    <S.Container>
      <MenuDrawer selectedTabName={selectedTabName} setSelectedTabName={setSelectedTabName} />
      <TabsContainer {...props} setSelectedTabName={setSelectedTabName} />

      <S.Content>
        <S.SearchContainer isInputFocused={isInputFocused} onClick={handleInputClick}>
          <S.Icon />

          <S.Input
            ref={inputRef}
            isInputFocused={isInputFocused}
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => textInput === "" && setIsInputFocused(false)}
            placeholder={t("Search routines")}
          />

          {isInputFocused && filteredRoutines.length > 0 && (
            <SuggestionsList suggestions={filteredRoutines} onSelectSuggestion={handleRoutineClick} />
          )}
        </S.SearchContainer>

        <TranslationModal />

        <S.BranchLogo src={require("../../../../assets/musa_logo_clara.png")} />
      </S.Content>
    </S.Container>
  )
}
