import styled from "rn-css"
import globalStyles from "../../../global/globalStyles"

export const Container = styled.View`
  flex: 1;
  margin: 0px 0 0 63px;
`

export const WarningView = styled.View`
  background-color: ${globalStyles.colors.removeButton};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 22px;
`

export const Text = styled.Text`
  color: ${globalStyles.colors.tertiary};
  font-size: 12px;
`

export const Content = styled.View`
  background-color: ${globalStyles.colors.background};
  padding: 0 5px;
  flex: 1;
  flex-direction: row;
`

export const DividingLine = styled.View`
  background-color: ${globalStyles.colors.primary};
  height: 100%;
  width: 2px;
`
