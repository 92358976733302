import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import applyMask from "../../../../services/applyMask"
import ImageField from "../components/ImageField"
import ModalForm from "../components/ModalForm"
import * as S from "./styles.web"

export default function DynamicCard({ item, onHandlePress, controller }) {
  const { t } = useTranslation()

  const entityFields = controller
    .entityFields()
    .filter(
      (item) =>
        !["E", "G"].includes(item.FIELDTYPE.VALUE) && item.T_DTD_ATR.CARDVISIBLE
    )
    .sort((a, b) => a.T_DTD_ATR.CARDORDERBY - b.T_DTD_ATR.CARDORDERBY)

  const [showModalForm, setShowModalForm] = useState(false)

  function sizeConvert(size) {
    switch (size?.toUpperCase()) {
      case "M":
        return "33.33%"
      case "L":
        return "48%"
      default:
        return "20%"
    }
  }

  function getFieldValue(field) {
    switch (field.FIELDTYPE.VALUE) {
      case "B":
        return item[field.ID] ? "Yes" : "No"
      case "L": return item[field.ID]?.LABEL
      case "O": {
        if (Array.isArray(item[field.ID])) return item[field.ID]?.map(item => item.LABEL).join(", ")
        else return item[field.ID].LABEL
      }
      case "K":
      case "N": {
        return item[field.ID]?.DESCRIPTION
      }
      case "C":
        if (!item[field.ID]) return 0
        return `R$${item[field.ID].toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}`
      default:
        return item[field.ID]
    }
  }

  function getMaskType(mask, value) {
    if (mask === "cnpj-cpf" && typeof value === "string") {
      return value.length >= 14 ? "99.999.999/9999-99" : "999.999.999-99"
    } else {
      return mask
    }
  }

  const handleOpenModal = (event) => {
    event.stopPropagation()
    Object.keys(item).forEach(
      (key) =>
        (controller.models()[controller.listEntityName()][key] = item[key])
    )
    setShowModalForm((s) => !s)
  }

  const RenderField = ({ field }) => {
    if (
      ["E", "G", "A"].includes(field.FIELDTYPE.VALUE) ||
      !field.T_DTD_ATR.CARDVISIBLE ||
      ["ID", "DESCRIPTION", "IMG"].includes(field.ID)
    )
      return

    const description = t(field.T_DTD_ATR.FIELDDESCRIPTION)

    const displayValue =
      field.T_DTD_ATR.MASK && getFieldValue(field)
        ? applyMask(
          getFieldValue(field),
          getMaskType(field.T_DTD_ATR.MASK, getFieldValue(field))
        )
        : getFieldValue(field) ?? "--"

    return (
      <S.FieldContent
        style={{ width: sizeConvert(field.T_DTD_ATR?.SIZE?.VALUE) }}
      >
        <S.FieldTitle title={description}>{description}</S.FieldTitle>
        <S.FieldValue>{displayValue}</S.FieldValue>
      </S.FieldContent>
    )
  }

  return (
    <S.Container onClick={() => {
      !showModalForm && onHandlePress(item)
    }}>
      <ImageField url={item?.IMG?.[0]?.URL} description={item?.DESCRIPTION} />

      <S.FieldContent style={{ width: "65%" }}>
        <S.FieldTitle>{t("Description")}</S.FieldTitle>
        <S.FieldValue>{item?.DESCRIPTION}</S.FieldValue>
      </S.FieldContent>
      <S.FieldContent style={{ width: "20%" }}>
        <S.FieldTitle>ID</S.FieldTitle>
        <S.FieldValue>{item?.ID}</S.FieldValue>
      </S.FieldContent>

      {entityFields.map((field) => {
        return <RenderField key={field.ID} field={field} />
      })}

      <S.InfoButton onClick={handleOpenModal}>i</S.InfoButton>

      {showModalForm && (
        <ModalForm
          showModalForm={showModalForm}
          setShowModalForm={setShowModalForm}
          controller={controller}
          entityFields={entityFields}
        />
      )}
    </S.Container>
  )
}
