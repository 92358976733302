import Ionicons from "@expo/vector-icons/Ionicons"
import update from "immutability-helper"
import React, { useCallback, useState } from "react"
import { useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"
import ItemTypes from "../ItemTypes"
import Card from "./components/Card"
import Modal from "./components/Modal"
import * as S from "./styles"

export default function VisibleFieldsList(props) {
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedField, setSelectedField] = useState(null)
  const [searchText, setSearchText] = useState("")

  const { t } = useTranslation()

  const { visibleFields, setVisibleFields, selectedOption } = props

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

  const isActive = canDrop && isOver

  let backgroundColor = defaultTheme["white"]

  if (isActive) backgroundColor = "#bbf7d0"
  else if (canDrop) backgroundColor = "#ffedd5"

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setVisibleFields((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    )
  }, [])

  const renderCard = useCallback((filter, index) => {
    if (filter.id === "TNSID") return

    return (
      <Card
        {...props}
        key={filter.id}
        filterData={filter}
        index={index}
        moveCard={moveCard}
        isHeaderConfiguration={isHeaderConfiguration}
        setModalVisible={setModalVisible}
        visibleFields={visibleFields}
        setSelectedField={setSelectedField}
      />
    )
  }, [])

  const filteredFields = visibleFields.filter(({ description }) => {
    const searchedValue = searchText.toUpperCase()
    const fieldDescription = t(description).toUpperCase()
    return fieldDescription.includes(searchedValue)
  })

  const isHeaderConfiguration = selectedOption.EXPRESSION === "T_MOV_HDR/T_SET_HDR"

  return (
    <S.Container>
      <S.Header>
        <Ionicons name="eye-outline" size={24} color={defaultTheme["gray-700"]} />
        <S.Title>{t("Visible Fields")}</S.Title>

        <S.SearchContainer>
          <S.SearchInput
            placeholder={t("Search")}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Ionicons name="search-outline" size={22} color={defaultTheme["gray-500"]} style={{
            position: "absolute",
            right: 7,
            zIndex: 10
          }} />
        </S.SearchContainer>
      </S.Header>

      <S.ListContainer
        ref={drop}
        data-testid="dustbin"
        style={{ backgroundColor }}
      >
        <S.List>
          {filteredFields.map((filter, i) => renderCard(filter, i))}
        </S.List>
      </S.ListContainer>
      {selectedField && (
        <Modal
          setModalVisible={setModalVisible}
          {...props}
          modalVisible={modalVisible}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
        />
      )}
    </S.Container>
  )
}
