import React, { useContext, useEffect, useState } from "react"
import { defaultTheme } from "../../../styles/themes/default"
import { RegistrationContext } from "../../screens/Registration/contexts/RegistrationProvider"
import Form from "../Form/index.web"
import Grid from "../Grid"
import LoadingAnimation from "../LoadingAnimation"
import ModalWrapper from "../Modal"
import FormToolbar from "../Toolbars/Form"
import TreeGrid from "../TreeGrid"
import * as S from "./styles"
import handleCopy from "./utils/handleCopy"
import handleDelete from "./utils/handleDelete"
import handleInsert from "./utils/handleInsert"
import handleSave from "./utils/handleSave"
import handleUndo from "./utils/handleUndo"

interface RegistrationBuilderProps {
  entity: string
  type: "Registration" | "Hierarchic"
  record?: object
}

export default function RegistrationBuilder({ entity, record }: RegistrationBuilderProps) {
  const { mode, models, updateForm, setUpdateForm, toggleMode, gridRef, loadingFormConfiguration, setLoadingFormConfiguration } = useContext(RegistrationContext)

  const functionProps = {
    gridRef,
    entity,
    models,
    toggleMode,
    mode,
    setUpdateForm,
    setLoadingFormConfiguration
  }

  const functions = {
    handleInsert: () => handleInsert(functionProps),
    handleCopy: () => handleCopy(functionProps),
    handleDelete: ({ changeLoadingModalVisibility, updateGrid = () => null }) =>
      handleDelete({
        ...functionProps,
        changeLoadingModalVisibility,
        updateGrid
      }),
    handleUndo: () => handleUndo(functionProps),
    handleSave: ({ changeLoadingModalVisibility }) =>
      handleSave({
        ...functionProps,
        changeLoadingModalVisibility
      })
  }

  useEffect(() => {
    const checkTableDataFields = (callback) => {
      models[entity].populate(record)
      const tableData = models._REGISTRATION_DATA[entity]
      setLoadingFormConfiguration(true)

      const interval = setInterval(() => {
        if (tableData.fields && tableData.fields.length > 0) {
          clearInterval(interval)
          setLoadingFormConfiguration(false)
          callback()
        }
      }, 100)
    }

    if (record) checkTableDataFields(() => {
      models[entity].populate(record)
      mode === "grid" ? toggleMode() : setUpdateForm(s => !s)
    })
  }, [record])

  const { type } = models._REGISTRATION_DATA[entity]
  const isHierarchicalScreen = type === "Hierarchic"

  return (
    <>
      <S.ContainerGrid style={{ display: mode === "grid" ? "block" : "none" }}>
        {isHierarchicalScreen ? (
          <TreeGrid
            entity={entity}
            gridRef={gridRef}
            functions={functions}
            models={models}
            rowDoubleClickCallback={toggleMode}
            screenType="registrationMaster"
          />
        ) : (
          <Grid
            entity={entity}
            gridRef={gridRef}
            functions={functions}
            models={models}
            rowDoubleClickCallback={toggleMode}
            screenType="registrationMaster"
          />
        )}
      </S.ContainerGrid>
      {mode !== "grid" && <S.ContainerForm>
        <FormToolbar
          entity={entity}
          gridRef={gridRef}
          toggleMode={toggleMode}
          models={models}
          functions={functions}
          setUpdateForm={setUpdateForm}
          updateForm={updateForm}
          screenType="registrationMaster"
        />
        <Form entity={entity} models={models} updateForm={updateForm} setUpdateForm={setUpdateForm} />
      </S.ContainerForm>}

      <ModalWrapper isVisible={loadingFormConfiguration}>
        <S.LoadingModal>
          <LoadingAnimation size="large" color={defaultTheme["cyan-800"]} />
        </S.LoadingModal>
      </ModalWrapper>
    </>
  )

}
