/* eslint-disable no-case-declarations */
import { closest, isNullOrUndefined, remove, removeClass } from "@syncfusion/ej2-base"
import { DataManager, Query } from "@syncfusion/ej2-data"
import { ContextMenuComponent } from "@syncfusion/ej2-react-navigations"
import {
  ActionEventArgs,
  Agenda,
  Day,
  DragAndDrop,
  ICalendarExport,
  ICalendarImport,
  Inject,
  Month,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  TimelineMonth,
  TimelineViews,
  TimelineYear,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
  Year
} from "@syncfusion/ej2-react-schedule"
import React from "react"
import handleDelete from "../../utils/handleDelete"
import handleSave from "../../utils/handleSave"
import "../../styles.css"

export default function Schedule({
  scheduleObj,
  contextMenuObj,
  eventData,
  calendarCollections,
  scheduleSettings
}) {

  const contextMenuItems = [
    {
      text: "New Event",
      iconCss: "e-icons e-plus",
      id: "Add"
    },
    {
      text: "New Recurring Event",
      iconCss: "e-icons e-repeat",
      id: "AddRecurrence"
    },
    {
      text: "Today",
      iconCss: "e-icons e-timeline-today",
      id: "Today"
    },
    {
      text: "Edit Event",
      iconCss: "e-icons e-edit",
      id: "Save"
    },
    {
      text: "Delete Event",
      iconCss: "e-icons e-trash",
      id: "Delete"
    },
    {
      text: "Delete Event",
      id: "DeleteRecurrenceEvent",
      iconCss: "e-icons e-trash",
      items: [
        {
          text: "Delete Occurrence",
          id: "DeleteOccurrence"
        },
        {
          text: "Delete Series",
          id: "DeleteSeries"
        }
      ]
    },
    {
      text: "Edit Event",
      id: "EditRecurrenceEvent",
      iconCss: "e-icons e-edit",
      items: [
        {
          text: "Edit Occurrence",
          id: "EditOccurrence"
        },
        {
          text: "Edit Series",
          id: "EditSeries"
        }
      ]
    }
  ]

  let selectedTarget = null

  const onScheduleActionComplete = (args: ActionEventArgs): void => {
    if (args.requestType === "eventCreated" || args.requestType === "eventChanged" || args.requestType === "eventRemoved") {
      for (const event of args.addedRecords) {
        event.IsPlanned = true
        handleSave({ eventData: event })
      }
      for (const event of args.changedRecords) {
        handleSave({ eventData: event })
      }
      for (const event of args.deletedRecords) {
        handleDelete({ eventData: event })
      }
    }
  }

  const contextMenuOpen = (args) => {
    const newEventElement = document.querySelector(".e-new-event")
    if (newEventElement) {
      remove(newEventElement)
      removeClass([document.querySelector(".e-selected-cell")], "e-selected-cell")
    }
    scheduleObj.current.closeQuickInfoPopup()
    const targetElement = args.event.target
    if (closest(targetElement, ".e-contextmenu")) {
      return
    }
    selectedTarget = closest(
      targetElement,
      ".e-appointment,.e-work-cells,.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells"
    )
    if (isNullOrUndefined(selectedTarget)) {
      args.cancel = true
      return
    }
    if (selectedTarget.classList.contains("e-appointment")) {
      const eventObj = scheduleObj.current.getEventDetails(selectedTarget)
      if (eventObj.RecurrenceRule) {
        contextMenuObj.current.showItems(["EditRecurrenceEvent", "DeleteRecurrenceEvent"], true)
        contextMenuObj.current.hideItems(["Add", "AddRecurrence", "Today", "Save", "Delete"], true)
      }
      else {
        contextMenuObj.current.showItems(["Save", "Delete"], true)
        contextMenuObj.current.hideItems(["Add", "AddRecurrence", "Today", "EditRecurrenceEvent", "DeleteRecurrenceEvent"], true)
      }
      return
    }
    else if ((selectedTarget.classList.contains("e-work-cells") || selectedTarget.classList.contains("e-all-day-cells")) &&
      !selectedTarget.classList.contains("e-selected-cell")) {
      removeClass([].slice.call(scheduleObj.current.element.querySelectorAll(".e-selected-cell")), "e-selected-cell")
      selectedTarget.setAttribute("aria-selected", "true")
      selectedTarget.classList.add("e-selected-cell")
    }
    contextMenuObj.current.hideItems(["Save", "Delete", "EditRecurrenceEvent", "DeleteRecurrenceEvent"], true)
    contextMenuObj.current.showItems(["Add", "AddRecurrence", "Today"], true)
  }

  const contextMenuSelect = (args) => {
    const selectedMenuItem = args.item.id
    let eventObj = {}
    if (selectedTarget && selectedTarget.classList.contains("e-appointment")) {
      eventObj = scheduleObj.current.getEventDetails(selectedTarget)
    }
    switch (selectedMenuItem) {
      case "Today":
        scheduleObj.current.selectedDate = new Date()
        break
      case "Add":
      case "AddRecurrence":
        const selectedCells = scheduleObj.current.getSelectedElements()
        const isRightClickInSelectedCells = selectedCells.some(cell => cell === selectedTarget)
        const activeCellsData = scheduleObj.current.getCellDetails(isRightClickInSelectedCells ? selectedCells : [selectedTarget])
        if (selectedMenuItem === "Add") {
          scheduleObj.current.openEditor(activeCellsData, "Add")
        }
        else {
          scheduleObj.current.openEditor(activeCellsData, "Add", false, 1)
        }
        break
      case "Save":
      case "EditOccurrence":
      case "EditSeries":
        if (selectedMenuItem === "EditSeries") {
          const query = new Query().where(scheduleObj.current.eventFields.id, "equal", eventObj.RecurrenceID)
          eventObj = new DataManager(scheduleObj.current.eventsData).executeLocal(query)[0]
        }
        scheduleObj.current.openEditor(eventObj, selectedMenuItem)
        break
      case "Delete":
        scheduleObj.current.deleteEvent(eventObj)
        break
      case "DeleteOccurrence":
      case "DeleteSeries":
        scheduleObj.current.deleteEvent(eventObj, selectedMenuItem)
        break
      default:
        break
    }
  }

  return (
    <div className='left-panel'>
      <div className='overview-scheduler'>
        <ScheduleComponent
          id='scheduler'
          cssClass='schedule-overview'
          ref={scheduleObj}
          width='95%'
          height='78%'
          style={{ marginLeft: 63 }}
          currentView={scheduleSettings.selectedView}
          //locale="pt"
          timezone="America/Sao_Paulo"
          dateFormat="dd/MM/yyyy"
          timeFormat='HH:mm'
          timeScale={{ enable: scheduleSettings.enableGridline }}
          eventSettings={{ dataSource: eventData.filter(item => scheduleSettings.selectedCalendars.includes(item.CalendarId)) }}
          actionComplete={onScheduleActionComplete}
        >
          <ResourcesDirective>
            <ResourceDirective
              field='CalendarId'
              title='Calendars'
              name='Calendars'
              groupIDField='projectId'
              dataSource={calendarCollections.filter(item => scheduleSettings.selectedCalendars.includes(item.CalendarId))}
              textField='CalendarText'
              idField='CalendarId'
              colorField='CalendarColor'
            />
          </ResourcesDirective>
          <ViewsDirective>
            <ViewDirective option='Day' />
            <ViewDirective option='Week' />
            <ViewDirective option='WorkWeek' />
            <ViewDirective option='Month' />
            <ViewDirective option='Year' />
            <ViewDirective option='Agenda' />
            <ViewDirective option='TimelineDay' />
            <ViewDirective option='TimelineWeek' />
            <ViewDirective option='TimelineWorkWeek' />
            <ViewDirective option='TimelineMonth' />
            <ViewDirective option='TimelineYear' />
          </ViewsDirective>
          <Inject services={[
            Day,
            Week,
            WorkWeek,
            Month,
            Year,
            Agenda,
            TimelineViews,
            TimelineMonth,
            TimelineYear,
            DragAndDrop,
            Resize,
            ICalendarImport,
            ICalendarExport
          ]} />
        </ScheduleComponent>
        <ContextMenuComponent
          id='overviewContextMenu'
          cssClass='schedule-context-menu'
          ref={contextMenuObj}
          target='.e-schedule'
          items={contextMenuItems}
          beforeOpen={contextMenuOpen}
          select={contextMenuSelect}
          locale="pt"
        />
      </div>
    </div>
  )
}

