import models from "../../../../models"
import Navigation from "../../../../models/navigation"
import Permissions from "../../../../models/permissions"
import showAlert from "../../../../services/showAlert"
import { homeScreenObserver } from "../../../screens/HomeScreen/index.web"
import { branchLogoObserver } from "../../BranchLogo"
import { headerObserver } from "../../Header/index.web"
import { menuDrawerObserver } from "../hooks/useMenu"
import updateBranch from "./updateBranch"

const branchSwap = ({ t, branchs, closeMenu }) => {
  showAlert({
    type: "select",
    selectInputOptions: branchs,
    title: t("Branch Swap"),
    text: t("This action will close all tabs."),
    titleType: "warning",
    handleConfirm: async (value) => {
      updateBranch(value)

      Navigation.closeTabs()

      await Permissions.initialize()
      await models.prepareClone()

      closeMenu()
      showAlert({
        title: "Branch changed",
        titleType: "success"
      })
      homeScreenObserver.notify()
      menuDrawerObserver.notify()
      branchLogoObserver.notify()
      headerObserver.notify()
    }
  })
}

export default branchSwap
